import React, { useEffect } from "react";
import "./Footer.scss";
import logo from "../../assets/acelogo-w.webp";
import { IoMailSharp } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
function Footer() {
  const gotoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", gotoTop);

    return window.removeEventListener("scroll", gotoTop);
  }, []);

  return (
    <>
      {/* before footer */}

      <div className="before_footer parent" id="contactus">
        <div className="before_footer_cont container">
          <div className="before_left">
            <h3>Contact Us</h3>
          </div>
          <div className="before_right">
            <a href="tel:+919820012037">
              <span className="icons">
                <FaPhone />
              </span>
              +91 9820012037
            </a>
            <a href="mailto:info@axonichealth.com">
              <span className="icons">
                <IoMailSharp />
              </span>
              info@axonichealth.com
            </a>
          </div>
        </div>
      </div>

      {/* footer  */}

      <div className="footer-parent parent">
        <div className="footer-cont container">
          <div className="left-f">
            <img src={logo} alt="" />
          </div>
          <div className="right-f">
            <ul className="list">
              <li>
                <Link to="/privacy">Privacy & Policy</Link>
              </li>
              <li>
                <a href="#">Terms & Conditions</a>
              </li>
            </ul>
            <p>ⓒAxonic Health</p>
          </div>
        </div>
      </div>

      <div className="pagetotop">
        <div className="arrowbtn" onClick={gotoTop} >
          <FaArrowUp />
        </div>
      </div>
    </>
  );
}

export default Footer;
