import React from "react";
import Testimonial from "../../comps/testimonial/Testimonial";
import "./Home.scss";
import gif1 from "../../assets/Registration.gif";
import gif2 from "../../assets/Easily_Accessible.gif";
import gif3 from "../../assets/Whatsapp_msg.gif";
import gif4 from "../../assets/Billing.gif";
import gif5 from "../../assets/Staff.gif";
import gif6 from "../../assets/patient.gif";

import check from "../../assets/gif/check.gif";
import whatsapp from "../../assets/gif/whatsappmsg.gif";
import billing from "../../assets/gif/newbilling.gif";
import newstaff from "../../assets/gif/newstaff.gif";
import easily from "../../assets/gif/easily.gif";
import newpatient from "../../assets/gif/newpatient.gif";
import ot from "../../assets/gif/OT_New.gif";
import thirtParty from "../../assets/gif/Third_party_new.gif";

import { Link } from "react-router-dom";
import video from "../../assets/video/acevideo.mp4";
import ReactPlayer from "react-player";

function Home() {
  const features = [];

  return (
    <>
      <div className="parent hero">
        <div className="container hero-cont">
          <h1>
            <span>ACE </span> Your Hospital Management the Smart Way
          </h1>

          <p>
            Your next-gen Hospital Information Management System (HIMS) that
            connects all departments, creating seamless operations for enhanced
            healthcare.
          </p>

          <a href="#features" className="btn">
            {" "}
            Know More
          </a>
        </div>
      </div>
      {/* section 2 starts here */}
      <div className="imgbox-wrapper parent">
        <div className="container imgbox-cont">
          <div className="imgbox glass glow" id="about_link">
            <div className="img-screen bg-img-cover">
              <ReactPlayer
                className="v-box"
                url={video}
                width="100%"
                height="100%"
                autoplay={true} // Automatically start the video when ready
                playing={true} // Prevent full-screen mode on mobile devices
                loop // Ensure the video loops
                playsinline
                muted // Mute the video (necessary for autoplay to work on most browsers)
                style={{ overflow: "hidden", borderRadius: "10px" }} // Prevent overflow of the video container
              />
            </div>
          </div>
        </div>
      </div>
      {/* section 3 starts here */}

      <div className="twocol parent">
        <div className="container twocol-cont">
          <div className="left">
            <h2>
              <span> All-in-one</span> Hospital <br /> Management System
            </h2>
            <p>
              AXONIC introduces ACE, a revolutionary Hospital Information
              Management System (HIMS) designed to transform hospital
              operations.
            </p>
            <a href="/#contactus" to="/" className="btn">
              Subscribe Now
            </a>
          </div>
          <div className="right">
            <div className="img-box glass">
              <div className="img bg-img-cover"></div>
            </div>
          </div>
        </div>
      </div>

      {/* bento box */}

      <div className="bento-parent parent" id="features">
        <div className="container bento-cont">
          <h3>
            <span>ACE Features</span> That Stand Out{" "}
          </h3>
          <p>
            A comprehensive suite of AI-driven tools and multilingual support,
            designed to streamline hospital management and enhance patient care.
          </p>

          <div className="bento">
            <div className="line">
              <div className="left glass">
                <h3>
                  Smart Patient <br /> <span>Check-In</span>
                </h3>
                <p>
                  Simplify patient registration and scheduling with Smart
                  Check-in, reducing waiting times and improving patient flow
                  across departments.{" "}
                </p>

                <img src={check} />
              </div>
              <div className="right glass">
                <h3>
                  Comprehensive <br />
                  <span>Electronic Medical Records (EMR)</span>
                </h3>
                <p>
                  Access all outpatient and inpatient data seamlessly and in
                  real time, ensuring precise, continuous care throughout the
                  patient journey.
                </p>

                <img src={easily} />
              </div>
            </div>
            <div className="line">
              <div className="left glass">
                <h3>
                  ABDM <br />
                  <span> Compliant Billing</span>
                </h3>
                <p>
                  Secure and accurate OPD and OPD billing compliant with
                  Ayushman Bharat Digital Mission standards.{" "}
                </p>

                <img src={billing} />
              </div>
              <div className="right glass">
                <h3>
                  Instant <br />
                  <span>WhatsApp Communication</span>
                </h3>
                <p>
                  Keep patients informed and engaged with direct, efficient
                  WhatsApp integration for follow-ups and communications.
                </p>

                <img src={whatsapp} />
              </div>
            </div>
            <div className="line">
              <div className="left glass">
                <h3>
                  Staff{" "}
                  <span>
                    Scheduling <br />
                    and Management
                  </span>
                </h3>
                <p>
                  Optimise shift management and resource allocation to maintain
                  smooth, effective daily operations.
                </p>

                <img src={newstaff} />
              </div>
              <div className="right glass">
                <h3>
                  Journey <br />
                  <span>Tracking Across Departments</span>
                </h3>
                <p>
                  Track each stage of the patient journey, from registration to
                  surgery and emergency, for consistent, quality care.
                </p>

                <img src={newpatient} />
              </div>
            </div>
            {/* new-two */}

            <div className="line">
              <div className="left glass">
                <h3>
                  Integrated <br />
                  <span>OT Management</span>
                </h3>
                <p>
                  Streamlined surgery scheduling and tracking to optimise OT
                  operations ensuring safety in alignment with WHO standards.{" "}
                </p>

                <img src={ot} />
              </div>
              <div className="right glass">
                <h3>
                  Third-Party <br />
                  <span>System Integration</span>
                </h3>
                <p>
                  Connect with financial and ICU monitoring systems for
                  comprehensive hospital management and real-time patient
                  monitoring.
                </p>

                <img src={thirtParty} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* testimonials section */}

      <Testimonial />

      {/* Before Footer Section */}

      <div className="before-footer parent">
        <div className="before-footer-cont container">
          <p className="sub-title">Simplify Your Hospital, ACE It.</p>
          <h4 className="title">
            Ready to Revolutionise Your Hospital? Start your journey with <br />
            <span>ACE today, and see the difference.</span>
          </h4>
          <Link to="/pricing" className="btn">
            Subscribe Now
          </Link>
        </div>
      </div>
    </>
  );
}

export default Home;
