import "./Header.scss";
import Hamburger from "hamburger-react";
import { Link } from "react-router-dom";
import logo from "../../assets/acelogo.webp";
import React, { useEffect, useState } from "react";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className={`header parent ${isScrolled ? "scrolled" : ""}`}>
        <div className="container">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Logo" />
            </a>
          </div>


          <div className="links glass">
            <Link to="/"> Home </Link>
            <a href="/#about_link">
              {" "}
              About{" "}
            </a>
            <Link to="/pricing"> Pricing </Link>
            <Link to="/pricing" className="btn">
              Subscribe
            </Link>
          </div>

          <div className="navbtn glass" onClick={() => setOpen(true)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="mobilenav">
          <div className="close glass" onClick={() => setOpen(false)}>
            <span></span>
            <span></span>
          </div>

          <div className="mobile-links">
            <Link to="/" onClick={() => setOpen(false)}>
              Home
            </Link>
            <a href="#about-link" onClick={() => setOpen(false)}>
              About
            </a>
            <Link to="/pricing" onClick={() => setOpen(false)}>
              Pricing
            </Link>
            <Link to="/pricing" className="btn" onClick={() => setOpen(false)}>
            Subscribe
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
