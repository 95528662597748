import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ReactPlayer from 'react-player';
import './Testimonial.scss';
import video1 from '../../assets/testimonial/gaurav.webm';
import video2 from '../../assets/testimonial/deepali.webm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';


function Testimonial() {
    const [playingIndex, setPlayingIndex] = useState(null); 

    const handlePlayPause = (index) => {
       
        if (playingIndex === index) {
            setPlayingIndex(null);
        } else {
            setPlayingIndex(index);
        }
    };

    const handleSlideChange = () => {
       
        setPlayingIndex(null);
    };

    return (
        <div className="testimonial-parent parent">
            <div className="testimonial-container container">
                
                <div className="testimonial-left">
                    <h2 className="testimonial-heading">
                    Hear From <br/> Our  <span>Existing Clients!</span>
                    </h2>
                </div>
                <div className="testimonial-right">
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={2}
                        centeredSlides={false}
                        pauseOnMouseEnter={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true, 
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        onSlideChange={handleSlideChange} 
                        breakpoints={{
                            300: { slidesPerView: 1, spaceBetween: 20 },
                            500: { slidesPerView: 1, spaceBetween: 20 },
                            640: { slidesPerView: 1, spaceBetween: 10 },
                            768: { slidesPerView: 1, spaceBetween: 10 },
                            1024: { slidesPerView: 2, spaceBetween: 10 },
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                        <div className="testi-cont">
        <div className="testi-stars">
            <span>★ ★ ★ ★ ★</span>
        </div>
        <div className="testi-para">
        “Choosing Axonic Health (Previously
Known As Clinivantage) Application Was
A Game-Changer For Us. Its User-Friendly
Interface And Robust Security Measures
Impressed Us. The Support Team's
Expertise In Healthcare IT Made The Setup
Smooth, And Their Ongoing Assistance
Has Been Exceptional. I Would Not
Hesitate To Recommend This Software To
Other Institutions.”
        </div>
        <div className="testi-user">
            <strong>Shriram Hospital </strong>
            
        </div>
    </div>
                        </SwiperSlide>

                        
                        <SwiperSlide>
                        <div className="testi-cont">
        <div className="testi-stars">
            <span>★ ★ ★ ★ ★</span>
        </div>
        <div className="testi-para">
        “Implementing The Hospital Information
Management System (HMIS) Has Been A
Game-Changer For Our Royal Mudhol
Hospital & Research Centre Healthcare
Facility. Simple Interfaces And User-Friendly
Interfaces Dramatically Increased Our
Productivity, And Also Allowing Us To Focus
More On Patient Care. Truly A Transformative
Solution For Us.”
        </div>
        <div className="testi-user">
            <strong>RMHRC Hospital</strong>
            
        </div>
    </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="testi-cont">
        <div className="testi-stars">
            <span>★ ★ ★ ★ ★</span>
        </div>
        <div className="testi-para">
        “As An IT Healthcare Professional, I Can
Attest To The Positive Impact Of The Hospital
Information Management System (HIMS) On
Our Daily Workflows. The System's Robust
Features Streamline Patient Data
Management, Ensuring Accurate And Timely
Information Access. It's A Reliable And
Indispensable Tool In Our Healthcare
Arsenal.”
        </div>
        <div className="testi-user">
            <strong>Rajrit Hospital</strong>
           
        </div>
    </div>
                        </SwiperSlide>
                       
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default Testimonial