import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "crypto-browserify";
import "stream-browserify";
import "assert";
import "util";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />

);

