import React, { useEffect, useState } from "react";
import "./Price.scss";
import { FaCheckDouble } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { use } from "framer-motion/client";

function Price({ setPackDetail, setDuration, setMasterData }) {
  const navigate = useNavigate();

  const plansData = [
    {
      id: 1,
      name: "Ace Lite",
      price: [50, 275, 500],
      old: [60, 300, 600],
    },
    {
      id: 2,
      name: "Ace Pro",
      price: [100, 550, 1000],
      old: [120, 600, 1200],
    },
    {
      id: 3,
      name: "Ace Advance",
      price: [150, 750, 1500],
      old: [180, 900, 1800],
    },
  ];

  const features = [
    { name: "Patient Registration", compare: [true, true, true] },
    { name: "Appointment Scheduling", compare: [true, true, true] },
    { name: "Patient Admission/Discharge", compare: [true, true, true] },
    { name: "EMR (Outpatient & Inpatient)", compare: [true, true, true] },
    { name: "OPD Billing", compare: [true, true, true] },
    { name: "IPD Billing", compare: [true, true, true] },
    { name: "Fund Management", compare: [true, true, true] },
    { name: "WhatsApp Integration", compare: [true, true, true] },
    { name: "NABH Quality Indicators", compare: [true, true, true] },
    { name: "Nursing Module", compare: [false, true, true] },
    { name: "Inventory Module", compare: [false, true, true] },
    { name: "Pharmacy Module", compare: [false, true, true] },
    { name: "Lab Information System", compare: [false, true, true] },
    { name: "Insurance & Corporate Billing", compare: [false, true, true] },
    { name: "Package Creation", compare: [false, true, true] },
    { name: "Procurement Module", compare: [false, false, true] },
    { name: "Blood Bank", compare: [false, false, true] },
    { name: "CSSD", compare: [false, false, true] },
    { name: "Linen & Laundry", compare: [false, false, true] },
    { name: "Diet & Kitchen", compare: [false, false, true] },
    { name: "Mortuary", compare: [false, false, true] },
    { name: "Comprehensive EMR", compare: [false, false, true] },
    {
      name: "User-Defined Examination Templates",
      compare: [false, false, true],
    },
    { name: "Operation Theatre Management", compare: [false, false, true] },
    { name: "AI-Enabled Features", compare: [false, false, true] },
  ];

  function planDetails(id) {
    setPackDetail(id);
    setDuration(plan);
    setMasterData(plansData);
    navigate("/pricing_form");
  }

  const [list, setList] = useState(6);
  const [showAll, setShowAll] = useState(false);
  const [plan, setPlan] = useState(0);

  const toggleShowAll = () => {
    setList(showAll ? 6 : features.length);
    setShowAll(!showAll);
  };

  return (
    <>
      <div className="price parent">
        <div className="container price-container">
          <h2>Our Pricing Plans</h2>
          <p>
            ACE offers tailored solutions designed to grow with you. Whether
            you’re a small clinic or a large hospital, ACE has the tools you
            need.
          </p>
          <div className="price-box">
            <div className="plans glass">
              <div
                className={plan === 0 ? "plan active" : "plan"}
                onClick={() => setPlan(0)}
              >
                {" "}
                Monthly{" "}
              </div>
              <div
                className={plan === 1 ? "plan active" : "plan"}
                onClick={() => setPlan(1)}
              >
                {" "}
                Semi Annual{" "}
              </div>
              <div
                className={plan === 2 ? "plan active" : "plan"}
                onClick={() => setPlan(2)}
              >
                {" "}
                Annually{" "}
              </div>
            </div>
            <div className="cards">
              {plansData.map((planData) => (
                <div className="card">
                  <div className="plan-type">{planData.name}</div>
                  <h1>
                    <span className="strike"> ${planData.old[plan]} </span>$
                    {planData.price[plan]}
                  </h1>
                  <p>
                    {" "}
                    {plan === 0
                      ? "per month"
                      : plan === 1
                      ? "per 6 months"
                      : "per year"}
                  </p>
                  <div className="lists">
                    {features.slice(0, list).map((feature) => (
                      <div
                        className={
                          feature.compare[planData.id - 1]
                            ? "list_items active"
                            : "list_items  "
                        }
                        key={feature.name}
                      >
                        <span className="icon">
                          {" "}
                          <FaCheckDouble />{" "}
                        </span>
                        {feature.name}
                      </div>
                    ))}
                  </div>
                  <button
                    className="btn"
                    onClick={() => planDetails(planData.id)}
                  >
                    Select Plan{" "}
                  </button>
                </div>
              ))}
            </div>
            <button className="btn" onClick={toggleShowAll}>
              {" "}
              {showAll ? "Show Less" : "Full Comparison"}{" "}
            </button>
          </div>
        </div>
      </div>

      {/* Before Footer Section */}
      <div className="before-footer parent">
        <div className="before-footer-cont container">
          <h4 className="title">
            {" "}
            <span>Need a tailored solution?</span>
            <br /> Customize Your Hospital's Requirements!{" "}
          </h4>
          <p className="sub-title">
            {" "}
            For large hospitals and enterprises, we offer custom pricing and
            modules. Contact us to create the perfect fit for your needs.
          </p>{" "}
          <br />
          <a className="btn" href="mailto:info@axonichealth.com ">
            {" "}
            Leave a Mail{" "}
          </a>
        </div>
      </div>
    </>
  );
}

export default Price;
