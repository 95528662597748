import React, { useEffect, useState } from "react";
import "./pricing_form.scss";
import { IoMdArrowDropdown } from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AutoComplete } from "antd";
import Razorpay from "razorpay";

import { Link } from "react-router-dom";
const Pricing_Form = ({ packDetail, duration, masterData }) => {
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectStateId, setSelectStateId] = useState("");
  const [selectDistrictId, setSelectDistrictId] = useState("");
  const [selectCitytId, setSelectCityId] = useState("");

  console.log(selectedCountryId, "country");
  // formdata inputs

  const [regData, setRegData] = useState({
    organizationName: "",
    unitName: "",
    countryId: "",
    cityId: "",
    districtId: "",
    stateId: "",
    areaId: "",
    postalCode: "",
    appUrl: "",
    address: "",
    subDomain: "",
    contactNumber: "",
    organizationEmailId: "",
    appId: packDetail,
    emailId: "",
    contactPerson: "",
    paymentDetails: {
      amount: "1",
      TransactionId: "",
    },
  });

  const [options, setOptions] = useState([]);
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  // Set default values based on the country/state/city selected
  useEffect(() => {
    setRegData((prevData) => ({
      ...prevData,
      unitName: prevData.organizationName,
      appUrl: prevData.subDomain,
      countryId: selectedCountryId,
      cityId: selectCitytId,
      districtId: selectDistrictId,
      stateId: selectStateId,
    }));
  }, [regData.organizationName, regData.subDomain, selectedCountryId, selectCitytId, selectDistrictId, selectStateId]);

  useEffect(() => {
    if (masterData.length === 0) {
      navigate("/pricing");
    }
  }, [masterData, navigate]);

  const handleSearch = (value) => {
    if (!value || value.includes("@")) {
      setOptions([]);
      return;
    }

    setOptions((prevOptions) =>
      prevOptions.map((domain) => ({
        label: `${value}@${domain}`,
        value: `${value}@${domain}`,
      }))
    );
  };

  const fetchSubDomains = async () => {
    try {
      const response = await axios.get(
        `https://dns.google/resolve?name=${regData.organizationName}`
      );

      const subDomains = response.data.Authority?.map((item) => ({
        label: item.data,
        value: item.data,
      })) || [];

      setOptions(subDomains);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (regData.organizationName) fetchSubDomains();
  }, [regData.organizationName]);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getActiveCountryList/0`);
      setCountry(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStateList = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getActiveStateListByCountryId`, {
        countryId: selectedCountryId,
      });
      setStates(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDistrictList = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getActiveDistrictListByStateId`, {
        stateId: selectStateId,
      });
      setDistrict(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCityList = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getActiveCityListByDistrictId`, {
        districtId: selectDistrictId,
      });
      setCity(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAreaList = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getAreaByCityId`, {
        cityId: selectCitytId,
      });
      setArea(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (selectedCountryId) fetchStateList();
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectStateId) fetchDistrictList();
  }, [selectStateId]);

  useEffect(() => {
    if (selectDistrictId) fetchCityList();
  }, [selectDistrictId]);

  useEffect(() => {
    if (selectCitytId) fetchAreaList();
  }, [selectCitytId]);

  const regDataTransfer = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://subscription.axonichealth.co.in/api/createOrganizationFromWeb", regData);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const initiatePayment = (e) => {
    e.preventDefault();
    if (typeof window.Razorpay === "undefined") {
      console.error("Razorpay SDK not loaded");
      return;
    }
    const options = {
      key: "rzp_live_7qfAOwTzkoJ612",
      amount: regData.paymentDetails.amount * 100,
      currency: "INR",
      name: regData.organizationName,
      description: "Test Transaction",
      handler: function (response) {
        setRegData((prevData) => ({
          ...prevData,
          paymentDetails: {
            ...prevData.paymentDetails,
            TransactionId: response.razorpay_payment_id,
          },
        }));
        regDataTransfer();
      },
      prefill: {
        name: regData.contactPerson,
        email: regData.organizationEmailId,
        contact: regData.contactNumber,
      },
      theme: { color: "#3399cc" },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    initiatePayment();
  };


  return (
    <>
      <div class="popup-form-parent parent">
        {masterData.length > 0 && (
          <div class="popup-form-cont container">
            <div className="pricing-box">
              <div className="content">
                <h1>${masterData[packDetail - 1].price[duration]}</h1>
                <h2>
                  <span> {masterData[packDetail - 1].name}</span>
                </h2>
                <p>
                  <strong>
                    {duration === 0
                      ? "Monthly"
                      : duration === 1
                      ? "6 Months"
                      : duration === 2
                      ? "Yearly"
                      : null}
                  </strong>
                </p>
              </div>

              <div className="price">
                <h2>${masterData[packDetail - 1].price[duration]}</h2>
              </div>

              <div className="action">
                <Link className="btn" to="/pricing">
                  Change Plan
                </Link>
              </div>
            </div>

            <h3>Please fill the form below to get to the payment page.</h3>

            <form action="" onSubmit={handleFormSubmit} >
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.organizationName}
                  onChange={(e) =>
                    setRegData({ ...regData, organizationName: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.organizationName !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Organization Name
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <AutoComplete
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    padding: "0px",
                    fontSize: "16px",
                    border: "none",
                    outline: "none",
                  }}
                  onSearch={handleSearch}
                  options={options}
                  className="form-input autocomplete"
                  value={regData.subDomain}
                  onChange={(value) =>
                    setRegData({ ...regData, subDomain: value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.subDomain !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  SubDomain
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.organizationEmailId}
                  onChange={(e) =>
                    setRegData({
                      ...regData,
                      organizationEmailId: e.target.value,
                    })
                  }
                />
                <label
                  for=""
                  className={
                    regData.organizationEmailId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Organization Email-id
                </label>
              </div>

              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.contactPerson}
                  onChange={(e) =>
                    setRegData({ ...regData, contactPerson: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.contactPerson !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Contact Person Name
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.contactNumber}
                  onChange={(e) =>
                    setRegData({ ...regData, contactNumber: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.contactNumber !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Contact Number
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.emailId}
                  onChange={(e) =>
                    setRegData({ ...regData, emailId: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.emailId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Personal Email-id
                </label>
              </div>

              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.address}
                  onChange={(e) =>
                    setRegData({
                      ...regData,
                      address: e.target.value,
                    })
                  }
                />
                <label
                  for=""
                  className={
                    regData.address !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Address
                </label>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  onChange={(e) => setSelectedCountryId(e.target.value)} // Update selectedCountryId
                  value={selectedCountryId}
                >
                  <option value="">Select Country</option>

                  {country.map((item, index) => (
                    <option key={index} value={item.countryId}>
                      {item.countryName}
                    </option>
                  ))}
                </select>

                <label
                  for=""
                  className={
                    regData.selectedCountryId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Country
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  value={selectStateId}
                  onChange={(e) => setSelectStateId(e.target.value)}
                >
                  <option value="">Select State</option>
                  {states &&
                    states.map((item, key) => (
                      <option value={item.stateId} key={key}>
                        {item.stateName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectStateId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  State
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  value={selectDistrictId}
                  onChange={(e) => setSelectDistrictId(e.target.value)}
                >
                  <option value="">Select District</option>
                  {district &&
                    district.map((item, key) => (
                      <option value={item.districtId} key={key}>
                        {item.districtName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectDistrictId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  District
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  value={selectCitytId}
                  onChange={(e) => setSelectCityId(e.target.value)}
                >
                  <option value="">Select City</option>
                  {city &&
                    city.map((item, key) => (
                      <option value={item.cityId} key={key}>
                        {item.cityName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectCitytId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  City
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  value={regData.area}
                  onChange={(e) =>
                    setRegData({ ...regData, area: e.target.value })
                  }
                >
                  <option value="">Select Area</option>
                  {area &&
                    area.map((item, key) => (
                      <option value={item.areaId} key={key}>
                        {item.areaName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.area !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Area
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.postalCode}
                  onChange={(e) =>
                    setRegData({ ...regData, postalCode: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.postalCode !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Postal Code
                </label>
              </div>
              {/* <div class={error ? "form-row error" : "form-row"}>
                <input type="text" className="form-input" />
                <label for="" className="input_label">
                  Number Of Beds
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input type="text" className="form-input" />
                <label for="" className="input_label">
                  Number Of Users
                </label>
              </div> */}

              <div className="btn-tab">
                <button className="btn" type="submit">
                  Proceed to Payment
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default Pricing_Form;
